import { capitalize } from '~/assets/js/utils/textUtils';
import { STORE_AB_PREFIXES } from '~/assets/js/const/abTests';

/**
 * Генерация названия геттера АБ теста
 * @param {StoreAbStateKeys} abName название АБ теста
 * @returns {StoreAbGettersKeys}
 */
export default function generateAbGetterName(abName) {
    return `${STORE_AB_PREFIXES.GETTER}${capitalize(abName)}`
}
