import { capitalize } from '~/assets/js/utils/textUtils';
import { STORE_AB_PREFIXES } from '~/assets/js/const/abTests';

/**
 * Генерация названия мутации АБ теста
 * @param {StoreInitialAbStateKeys} abName название АБ теста
 * @returns {StoreAbStateKeys}
 */
export default function generateAbStateName(abName) {
    return `${STORE_AB_PREFIXES.STATE}${capitalize(abName)}`
}
