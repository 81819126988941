import {gsap} from 'gsap/dist/gsap.js';
import {ScrollToPlugin} from 'gsap/dist/ScrollToPlugin.js';


export function splitThousands(num, separator = ' ', saveZero = false) {
    if (num === undefined || num === null) {
        // console.warn('[splitThousands] Wrong Number ', num);
        return '';
    }
    let tmp = num.toString().split('.');
    let value = tmp[0].replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    if (saveZero && tmp[1] || Number(tmp[1])) {
        value += `.${tmp[1]}`;
    }
    return value;
}

export function roundToMillions(num, accuracy = 1) {
    if (num === undefined || num === null) {
        console.warn('[roundToMillions] Wrong Number ', num);
        return '';
    }

    return (Number(num) / 1000000).toFixed(accuracy);
}

export function plural(num, postfixes) {
    if (num === 0) {
        return postfixes[2];
    }

    if (!num) {
        console.warn('[plural] Wrong Number ', num);
        return '';
    }

    let n = Math.abs(num);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return postfixes[2];
    }
    n %= 10;
    if (n === 1) {
        return postfixes[0];
    }
    if (n >= 2 && n <= 4) {
        return postfixes[1];
    }
    return postfixes[2];
}

export function prettyPhone(rawPhoneNumber) {
    if(rawPhoneNumber)
        return rawPhoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3-$4-$5');
    else
        return rawPhoneNumber;
}

export function cleanPhone(prettyPhoneNumber) {
    return prettyPhoneNumber.replace(/ |-|\(|\)|_/g, '');
}

/**
 * Преваращает телефон в формат +79512204521 или 79512204521
 * @param tel
 * @param hasPlus
 * @returns {string}
 */
export function phoneNormalize(tel, hasPlus = true){
    if (tel.toString().length < 8) return tel;
    tel = tel.toString().replace(/[^0-9+]/g, '');
    tel = (tel.substring(0,1) != '+' && tel.length < 11) ? '+7' + tel : tel;
    tel = tel.replace(/^(8|\+8)/, '+7');
    tel = (/^\+/.test(tel)) ? tel : '+' + tel;
    if (!hasPlus) {
        tel = tel.substring(1);
    }
    return tel;
}

export function bytesToSize(bytes) {
    if (!bytes) {
        console.warn('[bytesToSize] Wrong bytes ', bytes);
        return '';
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export function monthByNumber(num, type = 'full') {
    if (typeof num !== 'number' || isNaN(num) || num < 0 || num > 11) {
        console.warn('[monthByNumber] Wrong number ', num);
        return '';
    }
    const months = {
        0: {
            full: 'январь',
            short: 'янв',
            case: 'января',
        },
        1: {
            full: 'Февраль',
            short: 'Фев',
            case: 'февраля',
        },
        2: {
            full: 'Март',
            short: 'Мар',
            case: 'марта',
        },
        3: {
            full: 'Апрель',
            short: 'Апр',
            case: 'апреля',
        },
        4: {
            full: 'Май',
            short: 'Май',
            case: 'мая',
        },
        5: {
            full: 'Июнь',
            short: 'Июн',
            case: 'июня',
        },
        6: {
            full: 'Июль',
            short: 'Июл',
            case: 'июля',
        },
        7: {
            full: 'Август',
            short: 'Авг',
            case: 'Августа',
        },
        8: {
            full: 'Сентябрь',
            short: 'Сен',
            case: 'сентября',
        },
        9: {
            full: 'Октябрь',
            short: 'Окт',
            case: 'октября',
        },
        10: {
            full: 'Ноябрь',
            short: 'Ноя',
            case: 'ноября',
        },
        11: {
            full: 'Декабрь',
            short: 'Дек',
            case: 'декабря',
        },
    };

    return months[num][type];
}


export function dayByNumber(num, type = 'full') {
    if (num > 6) {
        console.warn('[dayByNumber] Wrong number,', num);
        return '';
    }

    const days = {
        0: {
            full: 'Воскресенье',
            short: 'вс',
        },
        1: {
            full: 'Понедельник',
            short: 'пн',
        },
        2: {
            full: 'Вторник',
            short: 'вт',
        },
        3: {
            full: 'Среда',
            short: 'ср',
        },
        4: {
            full: 'Четверг',
            short: 'чт',
        },
        5: {
            full: 'Пятница',
            short: 'пт',
        },
        6: {
            full: 'Суббота',
            short: 'сб',
        },
    };

    return days[num][type];
}

export function dateToString(date) {
    if (!date || !(date instanceof Date)) {
        console.log('[dateToString] Wrong date, ', date);
        return '';
    }

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month.toString().length < 2) {
        month = '0' + month;
    }
    if (day.toString().length < 2) {
        day = '0' + day;
    }
    return `${year}-${month}-${day}`;
}

export function getCookieFromString(cookie, name, decode = true) {
    const decodedCookie = decode ? decodeURIComponent(cookie) : cookie;

    if (decodedCookie) {
        let ca = decodedCookie.trim().split(';');
        let value = undefined;
        ca.forEach(item => {
            let param = item.split('=');
            if (param[0].trim() === name) {
                value = param[1];
            }
        });
        return value;
    }

    return null;
}

export function getCookie(name) {
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.trim().split(';');
    let value = false;
    ca.forEach(item => {
        let param = item.split('=');
        if (param[0].trim() === name) {
            value = param[1];
        }
    });
    return value;
}

export function chunkArray(numChunks, array) {
    const chunkSize = Math.ceil(array.length / numChunks);
    const result = [];

    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        result.push(chunk);
    }

    return result;
}
export function queryToObject(qs) {
    let obj = {};

    if (qs) {
        let params = qs.split('&');

        params.forEach(param => {
            let name = param.split('=')[0];
            let value = param.split('=')[1];
            if (name && value) {
                if (Object.prototype.hasOwnProperty.call(obj, name)) {
                    if (Array.isArray(obj[name])) {
                        obj[name].push(value);
                    } else {
                        obj[name] = [obj[name], value];
                    }
                } else {
                    obj[name] = value;
                }
            }
        });
    }
    return obj;
}

export function objectToQuery(obj) {
    let qs = '';
    for (let name in obj) {
        if (obj[name]) {
            if (Array.isArray(obj[name])) {
                obj[name].forEach(val => {
                    if (val) {
                        qs += `${name}=${val}&`;
                    }
                });
            } else {
                qs += `${name}=${obj[name]}&`;
            }
        }
    }
    return qs.slice(0, -1);
}

export function getOffset(el) {
    let rect = el.getBoundingClientRect();

    return {
        top: rect.top + window.pageYOffset,
        left: rect.left + window.pageXOffset,
    };
}

export function truncText(el, t) {
    let text = t || el.textContent;
    let wordArray = text.split(' ');

    while (el.scrollHeight > el.clientHeight) {
        wordArray.pop();
        el.innerHTML = wordArray.join(' ') + '...';
    }
}

export function isIe() {
    let ua = process.browser ? window?.navigator?.userAgent : '';
    let msie = ua.indexOf('MSIE ') > 0 || !!ua.match(/Trident.*rv:11\./);

    return msie > 0;
}

export function getSafariWindowHeight() {
    let h = 0;
    const div = document.createElement('DIV');

    div.style.position = 'fixed';
    div.style.height = '100%';
    div.style.width = 0;
    div.style.top = 0;

    document.documentElement.appendChild(div);
    h = div.offsetHeight;
    document.documentElement.removeChild(div);

    return h;
}

export function scrollbarWidth() {
    if (typeof document === 'undefined') return 0;

    const div = document.createElement('div');

    div.style.position = 'fixed';
    div.style.left = 0;
    div.style.visibility = 'hidden';
    div.style.overflowY = 'scroll';

    document.body.appendChild(div);
    const width = div.getBoundingClientRect().right;
    document.body.removeChild(div);

    return width;
}


export function lockBody() {
    document.documentElement.style.setProperty('--lock-offset', `${-document.body.clientWidth + window.innerWidth}px`);
    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${scrollbarWidth()}px`;
    document.body.style.touchAction = 'none';
}


export function unlockBody() {
    document.body.style.height = '';
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
    document.body.style.touchAction = '';
    document.documentElement.style.setProperty('--lock-offset', '');
}


export function scrollTo(id = '', offset = 0, force = false, duration = .5) {
    const target = document.getElementById(id || '__nuxt');

    if (target) {
        const position = target.getBoundingClientRect().top + window.pageYOffset;

        if (force) {
            window.scroll({
                top: position - offset,
                left: 0,
                behavior: 'instant',
            });
        } else {
            gsap.to(window, {
                duration,
                scrollTo:
                    { y: position, offsetY: offset },
            });
        }
    }
}


export function throttle(func, ms) {
    let isThrottled = false;
    let savedArgs;
    let savedThis;

    function wrapper() {

        if (isThrottled) {
            savedArgs = arguments;
            savedThis = this;
            return;
        }

        func.apply(this, arguments);

        isThrottled = true;

        setTimeout(function() {
            isThrottled = false;
            if (savedArgs) {
                wrapper.apply(savedThis, savedArgs);
                savedArgs = savedThis = null;
            }
        }, ms);
    }

    return wrapper;
}

export function convertToRomeQuarter(data) {
    switch (data) {
        case 2:
            return 'II';
        case 3:
            return 'III';
        case 4:
            return 'IV';
        default:
            return 'I';
    }
}

export function debounce(func, wait, immediate) {
    let timeout;
    return function executedFunction() {
        // eslint-disable-next-line
        const context = this;
        const args = arguments;

        function later() {
            timeout = null;

            if (!immediate) {
                func.apply(context, args);
            }
        }

        const callNow = immediate && !timeout;

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);

        if (callNow) {
            func.apply(context, args);
        }
    };
}

export function getNodeArray(response, name) {
    return response[name]?.edges.length ? response[name].edges.map(item => item.node) : [];
}

export function generateUniqueId() {
    const timestamp = Date.now().toString(36);
    const randomChars = Math.random().toString(36).substring(2, 8);

    return `${timestamp}${randomChars}`;
}

export function isTouchDevice() {
    const firstCheck = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

    if (firstCheck) {
        return firstCheck;
    }

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
        return true;
    }

    if (/android/i.test(userAgent)) {
        return true;
    }

    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
}

export function addHideMetrics(script) {
    return `if (!document.location.search.includes('nogtm=true')) {
              ${script}
           }`;
}

export function vhInjection() {
    const windowHeight = window.visualViewport ? window.visualViewport.height : window.innerHeight;
    const vh = windowHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

export function calcVhInit() {
    if (!window || !document?.documentElement) {
        return;
    }
    vhInjection();

    window.addEventListener('resize', debounce(vhInjection, 100));
    window.addEventListener('orientationchange', vhInjection);
    window.visualViewport?.addEventListener('resize', debounce(vhInjection, 100));
}

export function calcVhDestroy() {
    if (!window || !document?.documentElement) {
        return;
    }
    window.removeEventListener('resize', vhInjection, false);
}

export function getTextWidth(text, font, letterSpacing) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (ctx) {
        ctx.font = font; // { fontWeight, fontSize, fontFamily }
        if (letterSpacing) {
            ctx.letterSpacing = letterSpacing;
        }

        const value = ctx.measureText(text);

        return Math.ceil(value.width);
    }

    return 0;
}

export function getFontSize() {
    const html = window.document.documentElement;
    const style = window.getComputedStyle(html, null).getPropertyValue('font-size');
    return parseFloat(style);
}

/**
 * Симметричное различие массивов
 * @param arr1
 * @param arr2
 * @returns {*[]} - уникальные элементы — те, которые не находятся в обоих массивах одновременно
 */
export function symmetricArrayDifference(arr1, arr2) {
    const setArr1 = new Set(arr1);
    const setArr2 = new Set(arr2)

    return arr1.filter(el => !setArr2.has(el)).concat(arr2.filter(el => !setArr1.has(el)));
}

/**
 * Подсчет количества несоответствий между двумя простыми объектами
 * @param obj1
 * @param obj2
 * @returns {number}
 */
export function countNonMatches(obj1, obj2) {
    return Object.entries(obj1).reduce((accum, [key, value]) => {
        if (obj2[key] === undefined) {
            return ++accum;
        }
        if (Array.isArray(value) && Array.isArray(obj2[key])) {
            if (symmetricArrayDifference(value, obj2[key]).length > 0) {
                accum++
            }
        } else if (value !== obj2[key]) {
            accum++
        }

        return accum;
    }, 0)
}

/**
 *
 * @param event
 * @param data
 */
export function directPushToDataLayer(event, data = {}) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event,
        ...data,
    });
}

/**
 *
 * @param strToSanitize
 * @returns string
 */
export const clearHTMLTags = (strToSanitize) => {
    return strToSanitize.replace(/(<([^>]+)>)/gi, "");
};

/**
 * Конвертация rem в px
 * @param rem
 * @returns number
 */
export function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

/**
 * Возвращает высоту хедера в px
 * @param device
 * @returns number
 */
export function getHeaderHeight(device) {
    /** Высчитываем высоту хедера с учетом текущей ширины экрана */
    const variablePostfix = device.isDesktop ? '' : `${device.isTablet ? 'Tablet' : 'Mobile'}`
    const styles = getComputedStyle(document.documentElement);
    const headerHeight = parseFloat(styles.getPropertyValue(`--headerHeight${variablePostfix}`));

    return convertRemToPixels(headerHeight);
}
