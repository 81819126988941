<template>
    <div :class="$style.ModalHomeAdvantagesVideo">
        <div :class="$style.headerWrapper">
            <div
                :class="$style.header"
                class="container-home"
            >
                <div :class="$style.headerTitle">
                    {{ title }}
                </div>

                <div :class="$style.headerPagination">
                    <div
                        ref="pagination"
                        :class="$style.paginaton"
                    >
                    </div>
                </div>

                <div :class="$style.headerClose">
                    <UiCloseButton
                        color="primary-inversed"
                        @click="$emit('close')"
                    />
                </div>
            </div>
        </div>

        <div :class="$style.body">
            <iframe
                v-if="type === 'frame'"
                width="100%"
                height="100%"
                :src="video"
                frameborder="0"
                :allow="allow"
                allowfullscreen
            ></iframe>
            <video
                v-else
                :src="video"
                width="100%"
                height="100%"
                :class="$style.video"
                controls
                disablepictureinpicture
                autoplay
                playsinline
            ></video>
        </div>
    </div>
</template>

<script>
import UiCloseButton from '~/components/ui/home/UiCloseButton';

export default {
    name: 'ModalHomeAdvantagesVideo',

    components: {
        UiCloseButton,
    },

    props: {
        title: {
            type: String,
            default: 'Видео',
        },

        video: {
            type: String,
            default: '',
        },

        allow: {
            type: String,
            default: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
        },

        type: {
            type: String,
            default: 'frame',
            validator: value => {
                ['frame', 'video'].includes(value);
            },
        },
    },
};
</script>

<style lang="scss" module>
    .ModalHomeAdvantagesVideo {
        width: 100vw;
        height: 100vh;
    }

    .headerWrapper {
        border-bottom: .1rem solid #292a2c; // TODO: добавить цвет в кит или поменять
        background-color: $home-base-1;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 7.2rem;
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
    }

    .headerTitle {
        @include old-h4;

        color: $inversed;
    }

    .headerPagination {
        @include old-p1;

        color: #4e4e51; // TODO: добавить цвет в кит или поменять
    }

    .headerClose {
        //
    }

    .body {
        position: relative;
        width: 100%;
        height: calc(100vh - 7.2rem - .1rem);
        background-color: $home-base-1;
    }

    .slider {
        width: 100%;
        height: 100%;
    }

    .slide {
        width: 100%;
        height: 100%;
    }

    .buttons {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: calc(100% - $homeDesktopPadding * 2);
        transform: translate(-50%, -50%);
    }

    .tabs {
        position: absolute;
        bottom: 2.4rem;
        left: 50%;
        transform: translateX(-50%);
    }

    @include respond-to(xs) {
        :global(.vjs-control-bar) {
            padding-right: 16px;
            padding-left: 16px;
        }
    }

    .video {
        &::-webkit-media-controls-fullscreen-button {
            display: none;
        }
    }
</style>
