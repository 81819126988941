//queries
import createPostComment from '@/queries/center/createPostComment.graphql';
import sendFlatPdfToEmail from '@/queries/flat/sendFlatPdfToEmail.graphql';

const FormMixin = {
    data() {
        return {
            formErrors: [],
            formSuccess: false,
            formFailure: false,
            regex: {
                email: /^[a-z0-9./=?_-]+@[a-z0-9-]+\.[a-z0-9]+/i,
            }
        };
    },

    methods: {

        validateField(userData, errors, inputType) {

            if (inputType === 'name') {
                if (!userData?.name?.length) {
                    errors.push({ field: 'name', messages: ['Имя не должно быть пустым'] });
                }
            }

            if (inputType === 'phone') {
                if (userData?.phone?.length < 18 || (userData?.phone && userData?.phone[17] === '_')) {
                    errors.push({ field: 'phone', messages: ['Телефон введен неверно'] });
                }
            }

            if (inputType === 'surname') {
                if (!userData?.surname?.length) {
                    errors.push({ field: 'surname', messages: ['Фамилия не должна быть пустой'] });
                }
            }

            if (inputType === 'email') {
                if (!userData.email.match(this.regex.email)) {
                    errors.push({field: 'email', messages: ['Email введен неверно']});
                }
            }
        },
        async onFormSubmit(evt) {
            let errors = [];
            let bookingFailure = false;
            let newHomeFailure = false;
            try {
                const userData = Object.fromEntries(new FormData(evt.target));

                const isCallbackChecked = userData?.callback === 'true';
                console.log(isCallbackChecked);

                if (!isCallbackChecked) {
                    userData.phone = '';
                }

                if(userData?.category === 'comment') {
                    const {data} = await this.$axios.$post('/graphql/', {
                        query: createPostComment.loc.source.body,
                        variables: JSON.stringify({
                            input: {
                                post: userData?.post || '',
                                name: userData?.name || '',
                                text: userData?.text || '',
                                email: userData?.email || '',
                            },
                        }),
                    });
                }

                if (userData?.category === 'callme' ||
                    userData?.category === 'callback') {

                    if (!userData?.name?.length)
                        errors.push({field: 'name', messages: ['Имя не должно быть пустым']});

                    if (userData?.phone?.length < 18 || (userData?.phone && userData?.phone[17] === '_'))
                        errors.push({field: 'phone', messages: ['Телефон введен неверно']});

                    if (!errors.length && window.ctw) {
                        window.ctw.createRequest('main', userData.phone.replace(/[^+\d]+/g, '').slice(1), [
                            {
                                'name': 'Имя',
                                'value': userData.name,
                            },
                        ], function (success, data) {
                            console.log(success, data);
                        });
                    } else {
                        console.warn('onFormSubmit/error:', errors, window.ctw);
                    }

                    if (!errors.length) {
                        const data = {
                            name: userData?.name || '',
                            phone: userData.phone.replace(/[^+\d]+/g, ''),
                            email: userData?.email || '',
                            request_type: 'callback',
                            ad: userData?.marketing || 'false',
                        };

                        try {
                            const req = await this.$axios.$post('/api/request/', data);
                        }

                        catch(e) {
                            console.warn('onFormSubmit/error/request:', e);
                        }
                    }
                }
                if (userData?.category === 'sendPdf') {
                    let link = '';

                    if (process.env.NODE_ENV === 'production') {
                        link = `https://alia.moscow/pdf/flats/print/${userData.flat}/?landscape=1&format=a4`;
                    } else {
                        link = `https://new.alia.idacloud.ru/pdf/flats/print/${userData.flat}/?landscape=1&format=a4`;
                    }

                    const {data} = await this.$axios.$post('/graphql/', {
                        query: sendFlatPdfToEmail.loc.source.body,
                        variables: JSON.stringify({
                            url: userData? link : '',
                            email: userData?.email || '',
                            ad: !!(userData?.marketing && userData?.marketing !== 'false'),
                        }),
                    });
                }

                if (userData?.category === 'parking-storage-booking') {
                    if (!userData?.name?.length)
                        errors.push({field: 'name', messages: ['Имя не должно быть пустым']});

                    if (userData?.phone?.length < 18 || (userData?.phone && userData?.phone[17] === '_'))
                        errors.push({field: 'phone', messages: ['Телефон введен неверно']});

                    if (!userData.email.match(this.regex.email))
                        errors.push({field: 'email', messages: ['Email введен неверно']});

                    let type = userData.subtype === 'parking' ? 'parking' : 'kladovka';

                    if (!errors.length && window.ctw) {
                        window.ctw.createRequest(type, userData.phone.replace(/[^+\d]+/g, '').slice(1), [
                            {
                                'name': 'ФИО',
                                'value': userData.name
                            },
                            {
                                'name': 'Email',
                                'value': userData.email,
                            },
                        ], function (success, data) {
                            console.log(success, data);
                        });
                    } else {
                        console.warn('onFormSubmit/error:', errors, window.ctw);
                    }

                    if (!errors.length) {
                        let request_type = userData.subtype === 'parking' ? 'reservation_parking' : 'reservation_storage';
                        const data = {
                            name: userData.name,
                            phone: userData.phone.replace(/[^+\d]+/g, ''),
                            email: userData.email,
                            request_type: request_type,
                            ad: userData?.marketing || 'false',
                        };

                        try {
                            const req = await this.$axios.$post('/api/request/', data);
                        }

                        catch(e) {
                            console.warn('onFormSubmit/error/request:', e);
                        }
                    }
                }

                if (userData?.category === 'rosteh') {
                    if (!userData?.name?.length)
                        errors.push({field: 'name', messages: ['Имя не должно быть пустым']});

                    if (userData?.phone?.length < 18 || (userData?.phone && userData?.phone[17] === '_'))
                        errors.push({field: 'phone', messages: ['Телефон введен неверно']});

                    if (!userData?.email?.length)
                        errors.push({field: 'email', messages: ['Email не должен быть пустым']});

                    if (!userData.email.match(this.regex.email))
                        errors.push({field: 'email', messages: ['Email введен неверно']});

                    if (!errors.length) {
                        const data = {
                            name: userData.name,
                            job: userData.position || '',
                            phone: userData.phone.replace(/[^+\d]+/g, ''),
                            email: userData.email,
                            ad: userData?.marketing || 'false',
                        };

                        const req = await this.$axios.$post('/api/excursion/', data);
                    } else {
                        console.warn('onFormSubmit/error/rosteh:', errors);
                    }
                }

                if(userData?.category === 'buy_question') {
                    if (!userData?.name?.length)
                        errors.push({field: 'name', messages: ['Имя не должно быть пустым']});

                    if (userData?.phone?.length < 18 || (userData?.phone && userData?.phone[17] === '_'))
                        errors.push({field: 'phone', messages: ['Телефон введен неверно']});

                    if (!userData?.email?.length)
                        errors.push({field: 'email', messages: ['Email не должен быть пустым']});

                    if (!userData.email.match(this.regex.email))
                        errors.push({field: 'email', messages: ['Email введен неверно']});

                    if (!errors.length) {
                        const data = {
                            name: userData.name,
                            phone: userData.phone.replace(/[^+\d]+/g, ''),
                            email: userData.email,
                            request_type: userData.request_type,
                            ad: userData?.marketing || 'false',
                        };

                        try {
                            const req = await this.$axios.$post('/api/request/', data);
                        }

                        catch(e) {
                            newHomeFailure = true;
                            console.warn('onFormSubmit/error/request:', e);
                        }
                    }
                }

                if (userData?.category === 'rating') {
                    console.log(userData);
                    if (!userData?.name?.length)
                        errors.push({field: 'name', messages: ['Имя не должно быть пустым']});

                    if (isCallbackChecked && (userData?.phone?.length < 18 || (userData?.phone && userData?.phone[17] === '_')))
                        errors.push({field: 'phone', messages: ['Телефон введен неверно']});

                    if (! errors.length){
                        const data = {
                            name: userData.name,
                            phone: userData.phone.replace(/[^+\d]+/g, ''),
                            review: userData.text,
                            rating: userData.rating,
                            ad: userData?.marketing || 'false',
                        };

                        try {
                            const req = await this.$axios.$post('/api/rating/', data);
                        }

                        catch(e) {
                            newHomeFailure = true;
                            console.warn('onFormSubmit/error/request:', e);
                        }
                    }
                }


                if (userData?.category === 'presale') {

                    this.validateField(userData, errors, 'name');
                    this.validateField(userData, errors, 'company');
                    this.validateField(userData, errors, 'email');
                    this.validateField(userData, errors, 'phone');

                    if (!errors.length) {
                        const data = {
                            name: userData.name,
                            company: userData.company,
                            job: userData.position,
                            email: userData.email,
                            phone: userData.phone.replace(/[^+\d]+/g, ''),
                            request_type: userData.category
                        };

                        try {
                            await this.$axios.$post('/api/presale/', data);
                        } catch(e) {
                            newHomeFailure = true;
                            let serverErrors = Object.keys(e.response.data);
                            if (serverErrors.length === 1 && serverErrors[0] === 'request_type') {
                                this.$modal.open('ModalHomeSaleFormSuccess', {
                                    title: 'Произошла ошибка',
                                    description: 'Попробуйте повторить попытку снова или перезагрузите страницу!',
                                    hasTelegramLink: false,
                                    isError: true
                                });
                            }
                            else {
                                serverErrors.forEach((el) => {
                                    errors.push({ field: el, messages: [e.response.data[el][0]] });
                                });
                            }

                            console.error('onFormSubmit/error/request/presale:', e, '\n window.ctw: ' + window.ctw);
                        }
                    }

                    // Rutarget
                    const _rutarget = window._rutarget || [];
                    _rutarget.push({ event: 'thankYou', conv_id: 'form_sent' });
                }

                if (userData?.category === 'purchase_page') {
                    if (!userData?.name?.length)
                        errors.push({field: 'name', messages: ['Имя не должно быть пустым']});

                    if (userData?.phone?.length < 18 || (userData?.phone && userData?.phone[17] === '_'))
                        errors.push({field: 'phone', messages: ['Телефон введен неверно']});


                    if (!errors.length) {
                        const data = {
                            name: userData.name,
                            phone: userData.phone.replace(/[^+\d]+/g, ''),
                            request_type: userData.category,
                            ad: userData?.marketing || 'false',
                        };

                        try {
                            const req = await this.$axios.$post('/api/request/', data);
                        }
                        catch(e) {
                            console.warn('onFormSubmit/error/request:', e);
                        }

                        if (window.ctw) {
                            window.ctw.createRequest(userData.subtype, userData.phone.replace(/[^+\d]+/g, '').slice(1), [
                                {
                                    'name': 'Имя',
                                    'value': userData.name,
                                },
                            ], function (success, data) {
                                console.log(success, data);
                            });
                        } else {
                            console.warn('ctw not found onFormSubmit/error:', errors, window.ctw);
                        }
                    }
                }

                if (userData?.category === 'mortgage') {
                    if (!userData?.name?.length)
                        errors.push({field: 'name', messages: ['Имя не должно быть пустым']});

                    if (userData?.phone?.length < 18 || (userData?.phone && userData?.phone[17] === '_'))
                        errors.push({field: 'phone', messages: ['Телефон введен неверно']});


                    if (!errors.length) {
                        const data = {
                            name: userData.name,
                            phone: userData.phone.replace(/[^+\d]+/g, ''),
                            request_type: userData.category,
                            object_id: userData.flat,
                            ad: userData?.marketing || 'false',
                        };

                        try {
                            const req = await this.$axios.$post('/api/request/', data);
                        }
                        catch(e) {
                            console.warn('onFormSubmit/error/request:', e);
                        }

                        if (window.ctw) {
                            window.ctw.createRequest(userData.subtype, userData.phone.replace(/[^+\d]+/g, '').slice(1), [
                                {
                                    'name': 'Имя',
                                    'value': userData.name,
                                },
                            ], function (success, data) {
                                console.log(success, data);
                            });
                        } else {
                            console.warn('ctw not found onFormSubmit/error:', errors, window.ctw);
                        }
                    }
                }

            } catch (e) {
                errors = e;
                console.warn('FormMixin/error:', e);
            }

            if (errors.length) {
                this.formErrors = errors;
            } else if (bookingFailure) {
                this.formFailure = true;
            } else if (newHomeFailure) {
                this.formFailure = true;
            }
            else {
                this.formSuccess = true;
                if (this.$refs.modal) {
                    setTimeout(() => {
                        // this.openCloseModal();
                    }, 3000);
                }
            }
        },

        oninputChange() {
            this.formErrors = [];
        },

        getInputError(inputName) {
            if (!this.formErrors || !this.formErrors.length) return {};

            const error = this.formErrors.filter(err => err.field === inputName);
            return error && error.length ? error[0] : {};
        },
    }
};

export default FormMixin;
