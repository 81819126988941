import generateAbActionName from '~/assets/js/abTests/generators/generateAbActionName';
import generateAbMutationName from '~/assets/js/abTests/generators/generateAbMutationName';
import { STORE_AB_STATE } from '~/store/abTests/state';

/**
 * @type {import('~/assets/js/types/abTests/store').StoreAbActions}
 */
const STORE_AB_ACTIONS = {
    ...Object.keys(STORE_AB_STATE)
        .reduce((total, currentValue) => {
            total[generateAbActionName(currentValue)] = ({ commit }, payload) => {
                commit(generateAbMutationName(currentValue), payload);
            };
            return total;
        }, {}),
};

export default STORE_AB_ACTIONS;
