import generateAbMutationName from '~/assets/js/abTests/generators/generateAbMutationName';
import { STORE_AB_STATE } from '~/store/abTests/state';

/**
 * @type {import('~/assets/js/types/abTests/store').StoreAbMutations}
 */
const STORE_AB_MUTATIONS = {
    ...Object.keys(STORE_AB_STATE)
        .reduce((total, currentValue) => {
            total[generateAbMutationName(currentValue)] = (state, value) => state[currentValue] = value;
            return total;
        }, {}),
};

export default STORE_AB_MUTATIONS;
