import { getCookieFromString, getCookie } from '../assets/js/utils';

export default ({ $axios, req }, inject) => {
    // Create a custom axios instance
    const mindbox = $axios.create({
        headers: {
            common: {
                Accept: 'application/json',
            },
        },
    });
    const cookie = req && req.headers ? getCookieFromString(req.headers.cookie, 'mindboxDeviceUUID') : getCookie('mindboxDeviceUUID') ? getCookie('mindboxDeviceUUID') : '';

    // Set baseURL to something different
    mindbox.setBaseURL(`https://api.mindbox.ru/v3/operations/async?endpointId=alia-website&deviceUUID=${cookie}`);
    mindbox.setHeader('Authorization', 'Mindbox secretKey="JTf0phejMTMuJvMzVvPS"');

    // Inject to context as $api
    inject('mindbox', mindbox);
};
