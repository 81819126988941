import getStore from '~/assets/js/abTests/store';
import generateAbActionName from '~/assets/js/abTests/generators/generateAbActionName';
import { STORE_AB_PREFIXES } from '~/assets/js/const/abTests';

/**
 * Генерация инициализации АБ теста
 * @param {StoreAbStateKeys} abName название АБ теста
 */
export default function generateAbInit(abName) {
    const handler = () => {
        getStore().dispatch(`${STORE_AB_PREFIXES.STORE}${generateAbActionName(abName)}`, true);
    };

    window.addEventListener(abName, handler);
}
