import { generateAbInit, generateAbStateName } from 'assets/js/abTests';
import { STORE_AB_STATE } from '~/store/abTests/state';

export function abTestInit() {
    Object.keys(STORE_AB_STATE)
        .forEach(key => generateAbInit(key));

    window.initAb = function(abName) {
        window.dispatchEvent(new Event(generateAbStateName(abName)));
    };
}
