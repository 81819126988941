<template>
    <transition
        :enter-active-class="$style.contentEnterActive"
        :leave-active-class="$style.contentLeaveActive"
        :enter-class="$style.contentEnter"
        :leave-to-class="$style.contentLeaveTo"
        @after-leave="$emit('after-leave')"
    >
        <div
            v-show="visible"
            :class="[$style.ModalContainer, containerClassList]"
        >
            <div
                ref="modalBody"
                :class="[$style.body, bodyClassList]"
            >
                <div
                    v-if="isSubscribeModal"
                    :class="$style.subImage"
                >
                    <img
                        src="/images/common/subscribeModal.jpg"
                        alt="Подписаться"
                    >
                </div>
                <UiCloseButton
                    color="light"
                    :class="[$style.close, {[$style._aeroModal]: isAeroModal}]"
                    @click="$emit('close')"
                />
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
import UiCloseButton from '~/components/ui/home/UiCloseButton';

export default {
    name: 'ModalContainer',

    components: {
        UiCloseButton,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },

        isAeroModal: {
            type: Boolean,
            default: false,
        },

        isFlatModal: {
            type: Boolean,
            default: false,
        },

        isBookingModal: {
            type: Boolean,
            default: false,
        },

        isSubscribeModal: {
            type: Boolean,
            default: false,
        },

        isParkModal: {
            type: Boolean,
            default: false,
        },

        withoutPadding: {
            type: Boolean,
            default: false,
        },

        isMainPromoSuccess: {
            type: Boolean,
            default: false,
        },

        isFullScreen: {
            type: Boolean,
            default: false,
        },

        isCustom: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        containerClassList() {
            return {
                [this.$style._aeroModal]: this.isAeroModal,
                [this.$style._parkModal]: this.isParkModal,
                [this.$style._fullScreen]: this.isFullScreen,
                [this.$style._isCustom]: this.isCustom,
            };
        },

        bodyClassList() {
            return {
                [this.$style._aeroModal]: this.isAeroModal,
                [this.$style._flatModal]: this.isFlatModal,
                [this.$style._bookingModal]: this.isBookingModal,
                [this.$style._subModal]: this.isSubscribeModal,
                [this.$style._parkModal]: this.isParkModal,
                [this.$style._p0]: this.withoutPadding,
                [this.$style._mainPromoSuccess]: this.isMainPromoSuccess,
            };
        },
    },

    methods: {
        scrollToTop() {
            this.$refs.modalBody.scroll({ top: 0, left: 0, behavior: 'smooth' });
        },
    },
};
</script>

<style lang="scss" module>
    .ModalContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        padding: 2.4rem 0;

        .title {
            padding: 0 1.6rem;
        }

        @include respond-to(xs) {
            width: 100vw;
            height: 100%;
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;

            &._parkModal {
                position: absolute;
                bottom: 0;
                left: 0;
                height: auto;
                min-height: auto;
            }
        }

        &._fullScreen {
            width: 100%;
            padding: 0;
            flex-direction: column;

            .body {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                padding: 9rem 3.2rem 3.2rem 3.2rem;
                border-radius: 0;

                @include respond-to(sm) {
                    padding-top: 7.8rem;
                }

                @include respond-to(xxs) {
                    padding: 6rem 1.6rem;
                }
            }

            .close {
                top: 2.2rem;
                right: 2.2rem;

                @include respond-to(sm) {
                    top: 1.6rem;
                    right: 1.6rem;
                }
            }
        }

        &._isCustom {
            width: 100vw;
            margin: 0;

            .body {
                display: block;
                width: 100vw;
                height: 100vh;
                margin: 0;
                padding: 0;
                background: transparent;
            }

            .close {
                display: none;
            }
        }

        @include respond-to(xs) {
            &:has(._mainPromoSuccess) {
                max-width: 100vw;
            }
        }
    }

    .body {
        position: relative;
        width: 100%;
        padding: 6.4rem 10.7rem;
        background-color: $base-0;

        @include respond-to(xs) {
            height: 100%;
            padding: 32px $mobile-padding;
            border-radius: 0;
        }

        &._flatModal {
            padding: 9.6rem 6.4rem 5rem;
        }

        &._bookingModal {
            overflow-y: auto;
            height: 100%;
            padding: 7.6rem 8rem 3.2rem;

            @include respond-to(xs) {
                padding: 32px $mobile-padding;
            }
        }

        &._subModal {
            display: flex;
            padding-left: 0;

            @include respond-to(xs) {
                padding-left: $mobile-padding;
            }
        }

        &._parkModal {
            min-height: 160px;
        }

        &._mainPromoSuccess {
            display: flex;
            align-items: center;
            flex-direction: column;
            height: auto;
            min-height: 25rem;
            padding: 5.6rem 6.4rem;

            @include respond-to(sm) {
                min-width: 80vw;
                padding: 2.8rem 4rem;
            }

            @include respond-to(xs) {
                width: 100%;
                min-width: 100vw;
                height: 100%;
                padding: 1.6rem 2.4rem;
            }

            .close {
                border-radius: 0;
            }

            .close:after {
                background: $base-100;
            }
        }

        &._p0 {
            padding: 0;
            background-color: transparent;
        }

        & .close {
            position: absolute;
            top: 2.8rem;
            right: 2.8rem;
            z-index: 2;
            width: 3.2rem;
            height: 3.2rem;
            border-radius: 0;
            background-color: $base-0;

            @include respond-to(sm) {
                top: 2.4rem;
                right: 2.4rem;
            }

            @include respond-to(xs) {
                background-color: $base-50;
            }
        }
    }

    .subImage {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 41rem;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include respond-to(sm) {
            max-width: 30rem;
        }

        @include respond-to(xs) {
            display: none;
        }
    }

    .contentEnterActive,
    .contentLeaveActive {
        transition: all .4s ease-out;
    }

    .contentEnter,
    .contentLeaveTo {
        opacity: 0;
    }
</style>
