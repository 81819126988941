import generateAbGetterName from '~/assets/js/abTests/generators/generateAbGetterName';
import { STORE_AB_STATE } from '~/store/abTests/state';

/**
 * @type {import('~/assets/js/types/abTests/store').StoreAbGetters}
 */
const STORE_AB_GETTERS = {
    ...Object.keys(STORE_AB_STATE)
        .reduce((total, currentValue) => {
            total[generateAbGetterName(currentValue)] = state => state[currentValue];
            return total;
        }, {}),
};

export default STORE_AB_GETTERS;
