<template>
    <transition
        name="overlay-appear"
        @enter="handleEnter"
        @after-leave="afterOverlayClose"
    >
        <div
            v-show="isOverlayVisible"
            id="scroll"
            :class="[
                $style.overlay,
                options && options.classList ? options.classList: '',
                {[$style._opened]: isContentVisible},
            ]"
            @click.self="onClose"
            @keypress.esc="onClose"
        >
            <component
                :is="component"
                :data="options"
                :visible="isContentVisible"
                @close="onClose"
                @after-leave="afterContentClose"
            >
            </component>
        </div>
    </transition>
</template>

<script>
import { lockBody, unlockBody } from '~/assets/js/utils';

import VideoModal from '@/components/common/modals/VideoModal';
import ArchitectureModal from '@/components/common/modals/ArchitectureModal';
import InfraModal from '@/components/common/modals/InfraModal';
import ParkModal from '@/components/common/modals/ParkModal';
import MediaModal from '@/components/common/modals/MediaModal';
import CommentModal from '@/components/common/modals/CommentModal';
import ImagesModal from '@/components/common/modals/ImagesModal';
import ImageModal from '@/components/common/modals/ImageModal';
import CommonForm from '@/components/common/modals/CommonForm';
import SubscribeModal from '@/components/common/modals/SubscribeModal';
import SuccessModal from '@/components/common/modals/SuccessModal';
import TextModal from '@/components/common/modals/TextModal';
import MortgageModal from '@/components/common/modals//MortgageModal';
import SendPdfModal from '@/components/common/modals/SendPdfModal';
import MortgageITModal from '@/components/common/modals/MortgageITModal';
import BookingModal from '@/components/common/modals/BookingModal';
import CallbackModal from '~/components/landing/modals/CallbackModal';
import CallbackSuccessModal from '~/components/landing/modals/CallbackSuccessModal';
import CallbackFailModal from '~/components/landing/modals/CallbackFailModal';
import RostehSucceededFormSend from '~/components/common/modals/RostehSucceededFormSend';
import RateModalAdaptive from '~/components/common/modals/RateModalAdaptive';
import RostehNewSucceedFormModal from '~/components/common/modals/RostehNewSucceedFormModal';
import SponsorsModal from '~/components/common/modals/SponsorsModal';
import ModalHomeAdvantagesGalleryAlbums from '~/components/common/modals/home/ModalHomeAdvantagesGalleryAlbums';
import ModalHomeSaleFormSuccess from '~/components/common/modals/home/ModalHomeSaleFormSuccess';
import SatisfactionModal from '~/components/common/modals/SatisfactionModal';

export default {
    components: {
        RateModalAdaptive,
        CommentModal,
        MediaModal,
        InfraModal,
        ParkModal,
        ArchitectureModal,
        VideoModal,
        ImagesModal,
        ImageModal,
        CommonForm,
        SubscribeModal,
        SuccessModal,
        TextModal,
        MortgageModal,
        SendPdfModal,
        MortgageITModal,
        BookingModal,
        CallbackModal,
        CallbackSuccessModal,
        CallbackFailModal,
        RostehSucceededFormSend,
        RostehNewSucceedFormModal,
        SponsorsModal,
        ModalHomeAdvantagesGalleryAlbums,
        ModalHomeSaleFormSuccess,
        SatisfactionModal,
    },

    data() {
        return {
            component: null,
            options: null,
            newComponent: null,
            newOptions: null,
            isOverlayVisible: false,
            isContentVisible: false,
        };
    },

    watch: {
        $route() {
            this.onClose();
        },
    },

    beforeMount() {
        this.$modal.event.$on('open', this.onOpen);
        this.$modal.event.$on('close', this.onClose);
    },

    beforeDestroy() {
        this.$modal.event.$off('open', this.onOpen);
        this.$modal.event.$off('close', this.onClose);
    },

    methods: {
        handleEnter() {
            setTimeout(() => {
                this.isContentVisible = true;
            }, 500);
        },

        onOpen(component, options) {
            if (this.isOverlayVisible) {
                this.newComponent = component;
                this.newOptions = options || null;
                this.isContentVisible = false;
            } else {
                lockBody();
                this.isOverlayVisible = true;
                this.component = component;
                if (options) {
                    this.options = options;
                }
            }
        },

        onClose() {
            this.isContentVisible = false;
        },

        afterContentClose() {
            if (this.newComponent) {
                this.component = this.newComponent;
                this.options = this.newOptions;
                this.newComponent = null;
                this.newOptions = null;

                this.$nextTick(() => {
                    this.isContentVisible = true;
                });
            } else {
                this.component = null;
                this.options = null;
                this.isOverlayVisible = false;
            }
        },

        afterOverlayClose() {
            unlockBody();
        },
    },
};
</script>

<style lang="scss" module>
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .64);
        -webkit-overflow-scrolling: touch;

        &:global(.is-article) {
            overflow-y: auto;
        }

        &._opened {
            overflow-y: auto;
        }
    }
</style>
