import { capitalize } from '~/assets/js/utils/textUtils';
import { STORE_AB_PREFIXES } from '~/assets/js/const/abTests';

/**
 * Генерация названия действия АБ теста
 * @param {StoreAbStateKeys} abName название АБ теста
 * @returns {StoreAbActionsKeys}
 */
export default function generateAbActionName(abName) {
    return `${STORE_AB_PREFIXES.ACTION}${capitalize(abName)}`
}
