/**
 * Преобразование строки в строку, начинающуюся с заглавной буквы
 * @param {string} str
 * @returns {string}
 */
export function capitalize(str) {
    return str[0].toUpperCase() + str.slice(1);
}

/**
 * Получение snake_case из camelCase
 * @param {string} str
 * @returns {string}
 */
export function camelToSnakeCase(str) {
    return str[0].toLowerCase() + str.slice(1, str.length).replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}
