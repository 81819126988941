import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d8a8f054 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _320458be = () => interopDefault(import('../pages/album_of_planning_solutions_10F/index.vue' /* webpackChunkName: "pages/album_of_planning_solutions_10F/index" */))
const _a3374f46 = () => interopDefault(import('../pages/album-2/index.vue' /* webpackChunkName: "pages/album-2/index" */))
const _1a05a00f = () => interopDefault(import('../pages/all_planning_solutions_10C_10E/index.vue' /* webpackChunkName: "pages/all_planning_solutions_10C_10E/index" */))
const _7e9db64b = () => interopDefault(import('../pages/all_planning_solutions_10F/index.vue' /* webpackChunkName: "pages/all_planning_solutions_10F/index" */))
const _5b5eb8d4 = () => interopDefault(import('../pages/book/index.vue' /* webpackChunkName: "pages/book/index" */))
const _72ceb043 = () => interopDefault(import('../pages/brokers/index.vue' /* webpackChunkName: "pages/brokers/index" */))
const _06a8d918 = () => interopDefault(import('../pages/businesspark_map/index.vue' /* webpackChunkName: "pages/businesspark_map/index" */))
const _34031cfc = () => interopDefault(import('../pages/center/index.vue' /* webpackChunkName: "pages/center/index" */))
const _18937937 = () => interopDefault(import('../pages/commercial/index.vue' /* webpackChunkName: "pages/commercial/index" */))
const _d9025900 = () => interopDefault(import('../pages/contacts/index.vue' /* webpackChunkName: "pages/contacts/index" */))
const _b9268ccc = () => interopDefault(import('../pages/digital-maket/index.vue' /* webpackChunkName: "pages/digital-maket/index" */))
const _f3d619c0 = () => interopDefault(import('../pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _7fddc823 = () => interopDefault(import('../pages/flats/index.vue' /* webpackChunkName: "pages/flats/index" */))
const _8f3a5340 = () => interopDefault(import('../pages/landing/index.vue' /* webpackChunkName: "pages/landing/index" */))
const _7616f9cd = () => interopDefault(import('../pages/learn/index.vue' /* webpackChunkName: "pages/learn/index" */))
const _73309c4e = () => interopDefault(import('../pages/live/index.vue' /* webpackChunkName: "pages/live/index" */))
const _2d3281a8 = () => interopDefault(import('../pages/lk/index.vue' /* webpackChunkName: "pages/lk/index" */))
const _9c9d613a = () => interopDefault(import('../pages/ltb/index.vue' /* webpackChunkName: "pages/ltb/index" */))
const _6d01f0e6 = () => interopDefault(import('../pages/owners/index.vue' /* webpackChunkName: "pages/owners/index" */))
const _e0ef0a7e = () => interopDefault(import('../pages/parking/index.vue' /* webpackChunkName: "pages/parking/index" */))
const _19b35221 = () => interopDefault(import('../pages/play/index.vue' /* webpackChunkName: "pages/play/index" */))
const _470ccbc2 = () => interopDefault(import('../pages/policy/index.vue' /* webpackChunkName: "pages/policy/index" */))
const _5bbd39f3 = () => interopDefault(import('../pages/presale/index.vue' /* webpackChunkName: "pages/presale/index" */))
const _10418f82 = () => interopDefault(import('../pages/presale-offer/index.vue' /* webpackChunkName: "pages/presale-offer/index" */))
const _1bb70ce2 = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _a15a2aa0 = () => interopDefault(import('../pages/ps/index.vue' /* webpackChunkName: "pages/ps/index" */))
const _256ef6ae = () => interopDefault(import('../pages/purchase/index.vue' /* webpackChunkName: "pages/purchase/index" */))
const _0b9b3bac = () => interopDefault(import('../pages/qr-promo-2-2022/index.vue' /* webpackChunkName: "pages/qr-promo-2-2022/index" */))
const _6835deb1 = () => interopDefault(import('../pages/qr-promo-2022/index.vue' /* webpackChunkName: "pages/qr-promo-2022/index" */))
const _6b7a42da = () => interopDefault(import('../pages/rfpi/index.vue' /* webpackChunkName: "pages/rfpi/index" */))
const _38828cae = () => interopDefault(import('../pages/rosteh/index.vue' /* webpackChunkName: "pages/rosteh/index" */))
const _e9ca9c78 = () => interopDefault(import('../pages/storage/index.vue' /* webpackChunkName: "pages/storage/index" */))
const _03ebdf8e = () => interopDefault(import('../pages/test-calc/index.vue' /* webpackChunkName: "pages/test-calc/index" */))
const _010835cc = () => interopDefault(import('../pages/virtual-land/index.vue' /* webpackChunkName: "pages/virtual-land/index" */))
const _12bf3880 = () => interopDefault(import('../pages/whatsapp-redirect/index.vue' /* webpackChunkName: "pages/whatsapp-redirect/index" */))
const _70971dbe = () => interopDefault(import('../pages/work/index.vue' /* webpackChunkName: "pages/work/index" */))
const _a91b9cd0 = () => interopDefault(import('../pages/digital-maket/about/index.vue' /* webpackChunkName: "pages/digital-maket/about/index" */))
const _e19ac064 = () => interopDefault(import('../pages/digital-maket/infrastructure/index.vue' /* webpackChunkName: "pages/digital-maket/infrastructure/index" */))
const _5c7a4e24 = () => interopDefault(import('../pages/digital-maket/project/index.vue' /* webpackChunkName: "pages/digital-maket/project/index" */))
const _dea1dd76 = () => interopDefault(import('../pages/digital-maket/virtual-tour/index.vue' /* webpackChunkName: "pages/digital-maket/virtual-tour/index" */))
const _970001bc = () => interopDefault(import('../pages/trade-in/online-eval/index.vue' /* webpackChunkName: "pages/trade-in/online-eval/index" */))
const _8ec5e694 = () => interopDefault(import('../pages/flats_pdf/redirect/_id/index.vue' /* webpackChunkName: "pages/flats_pdf/redirect/_id/index" */))
const _014851db = () => interopDefault(import('../pages/flats/print/_article/index.vue' /* webpackChunkName: "pages/flats/print/_article/index" */))
const _34153efe = () => interopDefault(import('../pages/flats_pdf/_id/index.vue' /* webpackChunkName: "pages/flats_pdf/_id/index" */))
const _f31845c4 = () => interopDefault(import('../pages/flats/_slug/index.vue' /* webpackChunkName: "pages/flats/_slug/index" */))
const _d6ca3266 = () => interopDefault(import('../pages/project/_id/index.vue' /* webpackChunkName: "pages/project/_id/index" */))
const _5f50d2b0 = () => interopDefault(import('../pages/r/_type/_id/index.vue' /* webpackChunkName: "pages/r/_type/_id/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _71ea13ee = () => interopDefault(import('~/pages/purchase/index.vue' /* webpackChunkName: "" */))
const _7c39b2e3 = () => interopDefault(import('~/pages/flats/index.vue' /* webpackChunkName: "" */))
const _6b9b075f = () => interopDefault(import('~/pages/policy/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: '_active-link',
  linkExactActiveClass: '_exact-link',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _d8a8f054,
    name: "about"
  }, {
    path: "/album_of_planning_solutions_10F",
    component: _320458be,
    name: "album_of_planning_solutions_10F"
  }, {
    path: "/album-2",
    component: _a3374f46,
    name: "album-2"
  }, {
    path: "/all_planning_solutions_10C_10E",
    component: _1a05a00f,
    name: "all_planning_solutions_10C_10E"
  }, {
    path: "/all_planning_solutions_10F",
    component: _7e9db64b,
    name: "all_planning_solutions_10F"
  }, {
    path: "/book",
    component: _5b5eb8d4,
    name: "book"
  }, {
    path: "/brokers",
    component: _72ceb043,
    name: "brokers"
  }, {
    path: "/businesspark_map",
    component: _06a8d918,
    name: "businesspark_map"
  }, {
    path: "/center",
    component: _34031cfc,
    name: "center"
  }, {
    path: "/commercial",
    component: _18937937,
    name: "commercial"
  }, {
    path: "/contacts",
    component: _d9025900,
    name: "contacts"
  }, {
    path: "/digital-maket",
    component: _b9268ccc,
    name: "digital-maket"
  }, {
    path: "/favorites",
    component: _f3d619c0,
    name: "favorites"
  }, {
    path: "/flats",
    component: _7fddc823,
    name: "flats"
  }, {
    path: "/landing",
    component: _8f3a5340,
    name: "landing"
  }, {
    path: "/learn",
    component: _7616f9cd,
    name: "learn"
  }, {
    path: "/live",
    component: _73309c4e,
    name: "live"
  }, {
    path: "/lk",
    component: _2d3281a8,
    name: "lk"
  }, {
    path: "/ltb",
    component: _9c9d613a,
    name: "ltb"
  }, {
    path: "/owners",
    component: _6d01f0e6,
    name: "owners"
  }, {
    path: "/parking",
    component: _e0ef0a7e,
    name: "parking"
  }, {
    path: "/play",
    component: _19b35221,
    name: "play"
  }, {
    path: "/policy",
    component: _470ccbc2,
    name: "policy"
  }, {
    path: "/presale",
    component: _5bbd39f3,
    name: "presale"
  }, {
    path: "/presale-offer",
    component: _10418f82,
    name: "presale-offer"
  }, {
    path: "/project",
    component: _1bb70ce2,
    name: "project"
  }, {
    path: "/ps",
    component: _a15a2aa0,
    name: "ps"
  }, {
    path: "/purchase",
    component: _256ef6ae,
    name: "purchase"
  }, {
    path: "/qr-promo-2-2022",
    component: _0b9b3bac,
    name: "qr-promo-2-2022"
  }, {
    path: "/qr-promo-2022",
    component: _6835deb1,
    name: "qr-promo-2022"
  }, {
    path: "/rfpi",
    component: _6b7a42da,
    name: "rfpi"
  }, {
    path: "/rosteh",
    component: _38828cae,
    name: "rosteh"
  }, {
    path: "/storage",
    component: _e9ca9c78,
    name: "storage"
  }, {
    path: "/test-calc",
    component: _03ebdf8e,
    name: "test-calc"
  }, {
    path: "/virtual-land",
    component: _010835cc,
    name: "virtual-land"
  }, {
    path: "/whatsapp-redirect",
    component: _12bf3880,
    name: "whatsapp-redirect"
  }, {
    path: "/work",
    component: _70971dbe,
    name: "work"
  }, {
    path: "/digital-maket/about",
    component: _a91b9cd0,
    name: "digital-maket-about"
  }, {
    path: "/digital-maket/infrastructure",
    component: _e19ac064,
    name: "digital-maket-infrastructure"
  }, {
    path: "/digital-maket/project",
    component: _5c7a4e24,
    name: "digital-maket-project"
  }, {
    path: "/digital-maket/virtual-tour",
    component: _dea1dd76,
    name: "digital-maket-virtual-tour"
  }, {
    path: "/trade-in/online-eval",
    component: _970001bc,
    name: "trade-in-online-eval"
  }, {
    path: "/flats_pdf/redirect/:id",
    component: _8ec5e694,
    name: "flats_pdf-redirect-id"
  }, {
    path: "/flats/print/:article",
    component: _014851db,
    name: "flats-print-article"
  }, {
    path: "/flats_pdf/:id",
    component: _34153efe,
    name: "flats_pdf-id"
  }, {
    path: "/flats/:slug(korpus[\\w-]*)",
    component: _f31845c4,
    name: "flats-slug"
  }, {
    path: "/project/:id",
    component: _d6ca3266,
    name: "project-id"
  }, {
    path: "/r/:type?/:id",
    component: _5f50d2b0,
    name: "r-type-id"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/lgotnay-ipoteka",
    component: _71ea13ee
  }, {
    path: "/rassrochka",
    component: _71ea13ee
  }, {
    path: "/bronirovanie",
    component: _71ea13ee
  }, {
    path: "/ipoteka",
    component: _71ea13ee
  }, {
    path: "/digital-maket/flats/",
    component: _7c39b2e3
  }, {
    path: "*",
    component: _7c39b2e3
  }, {
    path: "/long-booking",
    component: _6b9b075f
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
