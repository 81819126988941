let store = {};

/**
 * Инициализация store
 * @param context
 */
export function initStore(context) {
    store = context.store;
}

/**
 * Получение store
 */
export default function getStore() {
    return store;
};
