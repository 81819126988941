import generateAbStateName from '~/assets/js/abTests/generators/generateAbStateName';
import { initialAbState } from '~/assets/js/const/abTests/state';

/**
 * @type {import('~/assets/js/types/abTests/store').StoreAbState}
 */
export const STORE_AB_STATE = {
    ...Object.fromEntries(Object.entries(initialAbState)
        .map(function([key, value]) {
            return [generateAbStateName(key), value];
        })),
};

export default () => STORE_AB_STATE;
