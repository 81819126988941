<template>
    <div
        v-if="visible"
        :class="$style.RostehNewSucceedFormModal"
    >
        <button
            :class="$style.close"
            @after-leave="$emit('after-leave')"
            @click="handleClose"
        >
            <svg :class="$style.closeIcon">
                <use xlink:href="#icon-close" :class="$style.closeIcon"/>
            </svg>
        </button>
        <div :class="$style.content">
            <div :class="$style.title">Спасибо, заявка принята!</div>
            <div :class="$style.info">Вступайте в чат-бот и узнавайте новости первыми</div>
            <a
                href="https://t.me/asterus_alia"
                target="_blank"
                :class="$style.tgLink"
            >
                <span :class="$style.icon">
                    <svg :class="$style.icon">
                        <use xlink:href="#icon-telegram" fill="#1d93d2"/>
                    </svg>
                </span>
                <span :class="$style.text">Новостной бот ALIA</span>
            </a>
            <div
                v-if="$device.isMobileOrTablet"
                :class="$style.countdown"
            >
                Окно автоматически закроется через <span :class="$style.highlighted">{{ timer }} сек</span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'RostehNewSucceedFormModal',
    data() {
        return {
            visible: true,
            timer: 10,
        };
    },

    watch: {
        timer() {
            if (this.timer === 0) {
                this.$emit('after-leave');
                this.$emit('close');
                this.visible = false;
            }
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.timerInterval();
        });
    },

    methods: {
        handleClose() {
            this.visible =false;
            this.$emit('close');
            this.$emit('after-leave');
        },

        timerInterval() {
            setInterval(() => {
                this.timer -= 1;
            }, 1000);
        },
    },
};
</script>

<style lang="scss" module>
    .RostehNewSucceedFormModal {
        position: relative;
        top: calc(50% - 229px);
        left: calc(50% - 656px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1312px;
        height: 458px;
        background-image: url("/images/pages/rosteh/rosteh-succeed-bg.png");

        @include respond-to(sm) {
            top: calc(50% - 283px);
            left: calc(50% - 264px);
            width: 528px;
            height: 566px;
            background-image: url("/images/pages/rosteh/rosteh-succeed-bg-tablet.png");
            background-size: cover;
        }

        @include respond-to(xs) {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("/images/pages/rosteh/rosteh-succeed-bg-mobile.png");
        }
    }

    .close {
        position: absolute;
        top: 28px;
        right: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: none;
        background-color: #fff;
        outline: none;
        transition: all ease-in-out .3s;
        cursor: pointer;

        .closeIcon {
            width: 16px;
            height: 16px;
            fill: #004046;
        }

        &:hover {
            transform: scale(1.1);
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 36px;
        text-align: center;
    }

    .title {
        font-size: 40px;
        font-weight: 700;
        line-height: 108%;
        color: #fff;
        opacity: .9;

        @include respond-to(sm) {
            font-size: 32px;
            line-height: 36px;
            letter-spacing: -.04em;
        }
    }

    .info {
        font-size: 16px;
        line-height: 152%;
        color: #fff;
        opacity: .64;

        @include respond-to(sm) {
            width: 320px;
            font-size: 14px;
            line-height: 140%;
        }
    }

    .tgLink {
        display: flex;
        align-items: center;
        width: 214px;
        height: 56px;
        padding: 21px 10px;
        border-radius: 4px;
        background: #f5f5f8;

        .text {
            font-size: 14px;
            font-weight: 500;
            line-height: 100%;
            color: #004046;
        }

        .icon {
            width: 40px;
            height: 40px;
        }
    }

    .countdown {
        position: absolute;
        bottom: 24px;
        font-size: 12px;
        line-height: 152%;
        color: rgba(255, 255, 255, .66);

        .highlighted {
            color: #fff;
        }
    }
</style>
