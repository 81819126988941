import { camelToSnakeCase } from '~/assets/js/utils/textUtils';
import { STORE_AB_PREFIXES } from '~/assets/js/const/abTests';

/**
 * Генерация названия мутации АБ теста
 * @param {StoreAbStateKeys} abName название АБ теста
 * @returns {StoreAbMutationsKeys}
 */
export default function generateAbMutationName(abName) {
    return `${STORE_AB_PREFIXES.MUTATION}${camelToSnakeCase(abName).toUpperCase()}`
}
