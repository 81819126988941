import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_4de1dc0a from 'nuxt_plugin_sentryserver_4de1dc0a' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_2faf2afc from 'nuxt_plugin_sentryclient_2faf2afc' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_smoothscrollpolyfill_72a4289e from 'nuxt_plugin_smoothscrollpolyfill_72a4289e' // Source: ./nuxt-polyfill/smoothscroll-polyfill.js (mode: 'all')
import nuxt_plugin_intersectionobserver_5167c258 from 'nuxt_plugin_intersectionobserver_5167c258' // Source: ./nuxt-polyfill/intersection-observer.js (mode: 'all')
import nuxt_plugin_libpluginf87c1bbc_0d2d65cc from 'nuxt_plugin_libpluginf87c1bbc_0d2d65cc' // Source: ./lib.plugin.f87c1bbc.js (mode: 'all')
import nuxt_plugin_axios_80c87b74 from 'nuxt_plugin_axios_80c87b74' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_index_256af38e from 'nuxt_plugin_index_256af38e' // Source: ../assets/js/directives/index.js (mode: 'all')
import nuxt_plugin_filters_a8b86778 from 'nuxt_plugin_filters_a8b86778' // Source: ../plugins/filters (mode: 'all')
import nuxt_plugin_axios_fb9c9a02 from 'nuxt_plugin_axios_fb9c9a02' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_mindbox_1e044882 from 'nuxt_plugin_mindbox_1e044882' // Source: ../plugins/mindbox (mode: 'all')
import nuxt_plugin_lazyload_adcf419e from 'nuxt_plugin_lazyload_adcf419e' // Source: ../plugins/lazyload (mode: 'all')
import nuxt_plugin_modal_fa52bd94 from 'nuxt_plugin_modal_fa52bd94' // Source: ../plugins/modal (mode: 'all')
import nuxt_plugin_veevalidate_6a1e9ac4 from 'nuxt_plugin_veevalidate_6a1e9ac4' // Source: ../plugins/vee-validate (mode: 'all')
import nuxt_plugin_vmask_3889a70c from 'nuxt_plugin_vmask_3889a70c' // Source: ../plugins/v-mask (mode: 'all')
import nuxt_plugin_image_6e91c892 from 'nuxt_plugin_image_6e91c892' // Source: ../config/plugins/image/image (mode: 'all')
import nuxt_plugin_isWebpSup_5b71cf9a from 'nuxt_plugin_isWebpSup_5b71cf9a' // Source: ../config/plugins/isWebpSup (mode: 'all')
import nuxt_plugin_sentry_0767d014 from 'nuxt_plugin_sentry_0767d014' // Source: ../config/plugins/sentry (mode: 'all')
import nuxt_plugin_vueTouch_97f9eba0 from 'nuxt_plugin_vueTouch_97f9eba0' // Source: ../plugins/vueTouch (mode: 'client')
import nuxt_plugin_vueportal_9c84e4ac from 'nuxt_plugin_vueportal_9c84e4ac' // Source: ../plugins/vue-portal (mode: 'all')
import nuxt_plugin_storeInit_145b253c from 'nuxt_plugin_storeInit_145b253c' // Source: ../plugins/storeInit.js (mode: 'all')
import nuxt_plugin_routeTracker_52b3e325 from 'nuxt_plugin_routeTracker_52b3e325' // Source: ../plugins/routeTracker (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"mode":"out-in","duration":500,"name":"page","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"htmlAttrs":{"lang":"ru"},"title":"Ália","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"},{"hid":"description","name":"description","content":"Ália"},{"hid":"og:image","name":"og:image","content":"https:\u002F\u002Falia.moscow\u002Fm\u002FAlia_BLD_10_Cam_004_01.jpg"},{"hid":"og:title","name":"og:title","content":"Ália"},{"hid":"og:description","name":"og:description","content":"Ália"},{"name":"msapplication-TileColor","content":"#ffffff"},{"name":"theme-color","content":"#ffffff"},{"name":"yandex-verification","content":"2ea6c9e63ae5a82c"},{"name":"google-site-verification","content":"PkfOhUTOM4oftwFvdA-kqOP-D96waQVEV2Y7vykuwSg"},{"name":"mailru-verification","content":"98856f9665e992de"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicons\u002Ffavicon.ico"},{"rel":"icon","type":"image\u002Fpng","sizes":"32x32","href":"\u002Ffavicons\u002Ffavicon-32x32.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"16x16","href":"\u002Ffavicons\u002Ffavicon-16x16.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"\u002Ffavicons\u002Fapple-touch-icon.png"},{"rel":"manifest","href":"\u002Ffavicons\u002Fsite.webmanifest"},{"rel":"mask-icon","href":"\u002Ffavicons\u002Fsafari-pinned-tab.svg","color":"#000000"}],"__dangerouslyDisableSanitizers":["script","noscript"],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_4de1dc0a === 'function') {
    await nuxt_plugin_sentryserver_4de1dc0a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_2faf2afc === 'function') {
    await nuxt_plugin_sentryclient_2faf2afc(app.context, inject)
  }

  if (typeof nuxt_plugin_smoothscrollpolyfill_72a4289e === 'function') {
    await nuxt_plugin_smoothscrollpolyfill_72a4289e(app.context, inject)
  }

  if (typeof nuxt_plugin_intersectionobserver_5167c258 === 'function') {
    await nuxt_plugin_intersectionobserver_5167c258(app.context, inject)
  }

  if (typeof nuxt_plugin_libpluginf87c1bbc_0d2d65cc === 'function') {
    await nuxt_plugin_libpluginf87c1bbc_0d2d65cc(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_80c87b74 === 'function') {
    await nuxt_plugin_axios_80c87b74(app.context, inject)
  }

  if (typeof nuxt_plugin_index_256af38e === 'function') {
    await nuxt_plugin_index_256af38e(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_a8b86778 === 'function') {
    await nuxt_plugin_filters_a8b86778(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_fb9c9a02 === 'function') {
    await nuxt_plugin_axios_fb9c9a02(app.context, inject)
  }

  if (typeof nuxt_plugin_mindbox_1e044882 === 'function') {
    await nuxt_plugin_mindbox_1e044882(app.context, inject)
  }

  if (typeof nuxt_plugin_lazyload_adcf419e === 'function') {
    await nuxt_plugin_lazyload_adcf419e(app.context, inject)
  }

  if (typeof nuxt_plugin_modal_fa52bd94 === 'function') {
    await nuxt_plugin_modal_fa52bd94(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_6a1e9ac4 === 'function') {
    await nuxt_plugin_veevalidate_6a1e9ac4(app.context, inject)
  }

  if (typeof nuxt_plugin_vmask_3889a70c === 'function') {
    await nuxt_plugin_vmask_3889a70c(app.context, inject)
  }

  if (typeof nuxt_plugin_image_6e91c892 === 'function') {
    await nuxt_plugin_image_6e91c892(app.context, inject)
  }

  if (typeof nuxt_plugin_isWebpSup_5b71cf9a === 'function') {
    await nuxt_plugin_isWebpSup_5b71cf9a(app.context, inject)
  }

  if (typeof nuxt_plugin_sentry_0767d014 === 'function') {
    await nuxt_plugin_sentry_0767d014(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueTouch_97f9eba0 === 'function') {
    await nuxt_plugin_vueTouch_97f9eba0(app.context, inject)
  }

  if (typeof nuxt_plugin_vueportal_9c84e4ac === 'function') {
    await nuxt_plugin_vueportal_9c84e4ac(app.context, inject)
  }

  if (typeof nuxt_plugin_storeInit_145b253c === 'function') {
    await nuxt_plugin_storeInit_145b253c(app.context, inject)
  }

  if (typeof nuxt_plugin_routeTracker_52b3e325 === 'function') {
    await nuxt_plugin_routeTracker_52b3e325(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
